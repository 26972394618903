function isDescendant(parent, child) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

$(document).ready(() => {
  initBanners();
  initProductsSlider();
  initReviewsSlider();
  initMediaSlider();
  // initBankSlider();
  // initArticlesSlider();
  // initReviewsSlider();
  // initVariantsSlider();
  // initMobileMenu();
  // initTabs();
  // initModals();
  // initProductSubscribe();
  // initProductH();
  // initCabinetNav();
  // initOrders();
  // initCustomSelect();
  // initNumberSlider();
  // initFiltersSections();
  // initFilters();
  // initProductCardModal();

  // Fancybox.bind("[data-fancybox]", {});
  //
  // $(".close-on-click-outside").click(function (e) {
  //   e.stopPropagation();
  //   const parent = $(e.target).closest(".close-on-click-outside");
  //
  //   $.each($(".close-on-click-outside.is-active"), (k, v) => {
  //     const active = $(v);
  //
  //     if (active.length && parent.length && active[0] !== parent[0]) {
  //       active.removeClass("is-active");
  //     }
  //   });
  // });

  // $(document).click(function (e) {
  //   if (
  //     !$(e.target).closest(".close-on-click-outside.is-active").length ||
  //     !$(e.target).is(".close-on-click-outside.is-active")
  //   ) {
  //     $(".close-on-click-outside.is-active").removeClass("is-active");
  //   }
  // });
});

//color = '' | 'green' | 'red'
//icon = 'success' | 'error'
function showNotification(color = "", icon = "success", text, time = 3000) {
  const notificationsContainer = document.querySelector(
    ".notifications-container"
  );
  const notification = document.createElement("div");
  notification.className = `notification ${color}`;
  notification.innerHTML = `
    <div class="container notification__container">
      <div class="notification__icon">
        <svg>
          <use xlink:href="/img/sprites/symbol/sprite.svg#${icon}"></use>
        </svg>
      </div>
      <div class="notification__text">${text}</div>
    </div>
  `;

  notificationsContainer.appendChild(notification);
  setTimeout(() => {
    notificationsContainer.removeChild(notification);
  }, time);
}

//NEW
function initBanners() {
  $.each($(".banners"), (k, v) => {
    const slider = v.querySelector(".banners__swiper");
    const pagination = v.querySelector(".banners__pagination");
    const prev = v.querySelector(".banners__prev");
    const next = v.querySelector(".banners__next");

    new Swiper(slider, {
      slidesPerView: 1,
      pagination: {
        el: pagination,
        clickable: true,
      },
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
    });
  });
}

function initProductsSlider() {
  $.each($(".products-slider"), (k, v) => {
    const slider = v.querySelector(".products-slider__swiper");
    const prev = v.querySelector(".products-slider__prev");
    const next = v.querySelector(".products-slider__next");
    const scrollbar = v.querySelector(".products-slider__scrollbar");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      scrollbar: {
        el: scrollbar,
      },
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 40,
          freeMode: false,
        },
        1180: {
          slidesPerView: 4,
          freeMode: false,
        },
      },
    });
  });
}

function initReviewsSlider() {
  $.each($(".reviews-slider"), (k, v) => {
    const slider = v.querySelector(".reviews-slider__swiper");
    const prev = v.querySelector(".reviews-slider__prev");
    const next = v.querySelector(".reviews-slider__next");
    const scrollbar = v.querySelector(".reviews-slider__scrollbar");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      scrollbar: {
        el: scrollbar,
      },
      breakpoints: {
        992: {
          slidesPerView: 2,
          spaceBetween: 40,
          freeMode: false,
        },
        1180: {
          slidesPerView: 3,
          freeMode: false,
        }
      },
    });
  });
}

function initMediaSlider() {
  $.each($(".media-slider"), (k, v) => {
    const slider = v.querySelector(".media-slider__swiper");
    const prev = v.querySelector(".media-slider__prev");
    const next = v.querySelector(".media-slider__next");
    const scrollbar = v.querySelector(".media-slider__scrollbar");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      scrollbar: {
        el: scrollbar,
      },
      breakpoints: {
        992: {
          slidesPerView: 2,
          spaceBetween: 40,
          freeMode: false,
        },
        1180: {
          slidesPerView: 3,
          freeMode: false,
        }
      },
    });
  });
}

function initBankSlider() {
  $.each($(".bank-slider"), (k, v) => {
    const slider = v.querySelector(".bank-slider__swiper");
    const prev = v.querySelector(".bank-slider__prev");
    const next = v.querySelector(".bank-slider__next");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        990: {
          slidesPerView: 5,
          spaceBetween: 13,
          freeMode: false,
        },
        1230: {
          slidesPerView: 7,
          spaceBetween: 13,
          freeMode: false,
        },
      },
    });
  });
}

function initArticlesSlider() {
  $.each($(".articles-slider"), (k, v) => {
    const slider = v.querySelector(".articles-slider__swiper");
    const prev = v.querySelector(".articles-slider__prev");
    const next = v.querySelector(".articles-slider__next");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 13,
      freeMode: true,
      navigation: {
        nextEl: next,
        prevEl: prev,
      },
      breakpoints: {
        990: {
          slidesPerView: 4,
          freeMode: false,
          spaceBetween: 9,
        },
        1230: {
          slidesPerView: 4,
          freeMode: false,
          spaceBetween: 33,
        },
      },
    });
  });
}

function initVariantsSlider() {
  $.each($(".variants-slider"), (k, v) => {
    const slider = v.querySelector(".variants-slider__swiper");

    new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 13,
      freeMode: true,
      mousewheel: {
        invert: true,
      },
      breakpoints: {
        1230: {
          spaceBetween: 26,
        },
      },
      on: {
        init: (swiper) => {
          if (!swiper.isBeginning) {
            v.classList.add("left-shadow");
          }

          if (swiper.progress < 1) {
            v.classList.add("right-shadow");
          }
        },
        progress: (swiper) => {
          if (swiper.activeIndex !== 0) {
            v.classList.add("left-shadow");
          } else {
            v.classList.remove("left-shadow");
          }

          if (swiper.progress >= 1 || swiper.isEnd) {
            v.classList.remove("right-shadow");
          } else {
            v.classList.add("right-shadow");
          }
        },
      },
    });
  });
}

function initMobileMenu() {
  $(".js-mobile-menu-toggle").click(function (e) {
    e.preventDefault();
    $("html").toggleClass("mobile-menu-active");
  });
}

function initTabs() {
  $(".tabs__nav a").click(function (e) {
    e.preventDefault();
    $(this)
      .addClass("is-active")
      .parent()
      .siblings()
      .find("a")
      .removeClass("is-active");
    $($(this).attr("href"))
      .addClass("is-active")
      .siblings()
      .removeClass("is-active");
  });
}

function initModals() {
  $(".js-modal-open").click(function (e) {
    e.preventDefault();
  });

  $(".js-modal-close").click(function (e) {
    e.preventDefault();
    $(this).closest(".modal").removeClass("is-active");

    if ($(".modal.is-active").length === 0) {
      $("html").removeClass("modal-active");
    }
  });
}

function initProductSubscribe() {
  $(".product-variant.out-of-stock").click(function (e) {
    $("html").addClass("modal-active");
    $("#product-varinat-subscribe").addClass("is-active");
    $(".product-subscribe__variant-text").text($(this).attr("data-text"));
  });

  $(".product-subscribe__form").submit(function (e) {
    e.preventDefault();
    $("html").removeClass("modal-active");
    $("#product-varinat-subscribe").removeClass("is-active");
    showNotification("green", "success", "Подписка на товар оформлена");
  });
}

function initProductH() {
  $(".product-h__expand-btn").click(function (e) {
    e.preventDefault();
    $(this).closest(".product-h").toggleClass("expanded");
  });
}

function initCabinetNav() {
  $(".js-cabinet-nav-link").click(function (e) {
    e.preventDefault();
    $($(this).attr("href"))
      .addClass("is-active")
      .siblings()
      .removeClass("is-active");
    $(".cabinet__grid").addClass("main-active");
  });

  $(".js-cabinet-back").click(function (e) {
    e.preventDefault();
    $(".cabinet__grid").removeClass("main-active");
  });
}

function initOrders() {
  $(".js-order-card-toggle").click(function (e) {
    e.preventDefault();
    $(this).closest(".order-card").toggleClass("is-active");
  });
}

function initCustomSelect() {
  $(".custom-select__toggle").click(function (e) {
    e.preventDefault();
    $(this).closest(".custom-select").toggleClass("is-active");
  });

  $(".custom-select__option").click(function (e) {
    e.preventDefault();
    $(this)
      .closest(".custom-select")
      .removeClass("is-active")
      .find(".custom-select__text")
      .text($(this).text());
  });
}

function initNumberSlider() {
  $.each($(".number-slider"), (k, v) => {
    const suffix = $(v).attr("data-suffix");
    const min = parseInt($(v).attr("data-min"));
    const max = parseInt($(v).attr("data-max"));
    const minInitial = parseInt($(v).attr("data-initial-min"));
    const maxInitial = parseInt($(v).attr("data-initial-max"));
    const minInput = $(v).find(".number-slider__min");
    const maxInput = $(v).find(".number-slider__max");
    const inputs = $(v).find(".number-slider__input");
    const sliderElement = $(v).find(".number-slider__main");

    minInput.val(min + suffix);
    maxInput.val(max + suffix);

    noUiSlider.create(sliderElement[0], {
      start: [minInitial, maxInitial],
      connect: true,
      step: parseInt($(v).attr("data-step")) || 1,
      range: {
        min: min,
        max: max,
      },
    });

    sliderElement[0].noUiSlider.on("update", function (values, handle) {
      console.log(values);
      minInput.val(parseInt(values[0]) + suffix);
      maxInput.val(parseInt(values[1]) + suffix);
    });

    inputs.on("focus", function () {
      $(this).val($(this).val().replace(suffix, ""));
    });

    inputs.on("blur", function () {
      const newVal = parseInt($(this).val());
      $(this).val(newVal + suffix);

      if ($(this).hasClass("number-slider__min")) {
        sliderElement[0].noUiSlider.set([parseInt($(this).val()), null]);
      } else if ($(this).hasClass("number-slider__max")) {
        sliderElement[0].noUiSlider.set([null, parseInt($(this).val())]);
      }
    });

    inputs.on("keypress", function (e) {
      if (e.which == 13) {
        $(this).blur();
      }
    });
  });
  /* 
    noUiSlider.create(slider, {
    start: [20, 80],
    connect: true,
    range: {
        'min': 0,
        'max': 100
    }
    });
  */
}

function initFiltersSections() {
  $(".filters__section-toggle").click(function () {
    $(this).closest(".filters__section").toggleClass("is-active");
  });
}

function initFilters() {
  $(".filters-btn").click(function () {
    $("html").addClass("filters-active");
  });

  $(".filters__close, .filters__show").click(function () {
    $("html").removeClass("filters-active");
  });
}

function initProductCardModal() {
  $(".product-card__btn").click(function () {
    $("html").addClass("modal-active");
    $(this)
      .closest(".product-card")
      .find(".product-card__select-variant-modal")
      .addClass("is-active");
  });
}
